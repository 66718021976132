import React, { useState } from 'react'
import Background from '../../images/background.JPG'

import linkedin from '../../images/linkedin.svg'
import github from '../../images/github.svg'
import ig from '../../images/ig.svg'

import {
    HeroContainer,
    HeroBg,
    ImgBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    SocialContainer
} from './HeroElements'

import {
    SocialWrapper,
    SocialCard,
    SocialIcon
} from '../Footer/FooterElements'

import { Button } from '../ButtonElement'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
                <ImgBg src={Background} />
            </HeroBg>
            <HeroContent>
                <HeroH1>I'm Viet An Truong.</HeroH1>
                <HeroP>I am a Full Stack Engineer with a passion for creating dynamic and accessible websites</HeroP>
                <HeroBtnWrapper>
                    <Button
                        to='about'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-20}
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary='true'
                        dark='true'
                    >
                        View my work
                        {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
                <SocialContainer>
                    <SocialWrapper>
                        <SocialCard target="_blank" href="https://github.com/ItsMarcoMSF" rel="noopener noreferrer">
                            <SocialIcon src={github} />
                        </SocialCard>
                        <SocialCard target="_blank" href="https://www.linkedin.com/in/vietantruong/" rel="noopener noreferrer">
                            <SocialIcon src={linkedin} />
                        </SocialCard>
                        <SocialCard target="_blank" href="https://www.instagram.com/itsmarco_tva/" rel="noopener noreferrer">
                            <SocialIcon src={ig} />
                        </SocialCard>
                    </SocialWrapper>
                </SocialContainer>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
