import styled from "styled-components";

export const FooterContainer = styled.div`
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(#010606, #5a7a6f); */
    background: #5a7a6f;

    @media screen and (max-width: 800px) {
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        height: 200px;
    }
`

export const SocialWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
`

export const SocialCard = styled.a`
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items : center;
    border-radius : 10px;
    max-height: 50px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.1) ;
        /* background: #fff; */
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const SocialIcon = styled.img`
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
`

export const TM = styled.p`
    margin-top: 30px;
    font-size: 1rem;
    text-align: center;
`