import React from 'react'
import linkedin from '../../images/linkedin.svg'
import github from '../../images/github.svg'
import ig from '../../images/ig.svg'

import {
    FooterContainer,
    SocialWrapper,
    SocialCard,
    SocialIcon,
    TM
} from './FooterElements'

const Footer = () => {
    return (
        <FooterContainer>
            <SocialWrapper>
                <SocialCard target="_blank" href="https://github.com/ItsMarcoMSF" rel="noopener noreferrer">
                    <SocialIcon src={github} />
                </SocialCard>
                <SocialCard target="_blank" href="https://www.linkedin.com/in/vietantruong/" rel="noopener noreferrer">
                    <SocialIcon src={linkedin} />
                </SocialCard>
                <SocialCard target="_blank" href="https://www.instagram.com/itsmarco_tva/" rel="noopener noreferrer">
                    <SocialIcon src={ig} />
                </SocialCard>
            </SocialWrapper>
            <TM>Viet An Truong © 2021</TM>
        </FooterContainer>
    )
}

export default Footer
