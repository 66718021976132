import React from 'react'
import Icon1 from '../../images/Icon1.png'
import Icon2 from '../../images/Icon2.png'
import Icon3 from '../../images/Icon3.png'
import Icon5 from '../../images/Icon5.png'

import {
    ProjectsContainer,
    ProjectsCard,
    ProjectsH1,
    ProjectsH2,
    ProjectsIcon,
    ProjectsP,
    ProjectsWrapper,
} from './ProjectsElements'

const Projects = () => {
    return (
        <ProjectsContainer id="projects">
            <ProjectsH1>Recent Projects</ProjectsH1>
            <ProjectsWrapper>
                <ProjectsCard target="_blank" href="https://web-scraper-l3ew56ox1-viet-an-truongs-projects.vercel.app/" rel="noopener noreferrer">
                    <ProjectsIcon src={Icon5}/>
                    <ProjectsH2>PriceWise</ProjectsH2>
                    <ProjectsP>Full Stack, NextJS, Cheerio, Web Scraping</ProjectsP>
                </ProjectsCard>
                <ProjectsCard target="_blank" href="https://marcosearch.netlify.app" rel="noopener noreferrer">
                    <ProjectsIcon src={Icon1}/>
                    <ProjectsH2>Search Engine UI</ProjectsH2>
                    <ProjectsP>Front-end, ReactJS, Google Search API, Tailwind CSS</ProjectsP>
                </ProjectsCard>
                <ProjectsCard target="_blank" href="https://bugtracker-marco.netlify.app" rel="noopener noreferrer">
                    <ProjectsIcon src={Icon2}/>
                    <ProjectsH2>Bug Tracker</ProjectsH2>
                    <ProjectsP>JAM stack, ReactJS, FaunaDB, Netlify Functions</ProjectsP>
                </ProjectsCard>
                <ProjectsCard target="_blank" href="https://github.com/ItsMarcoMSF/ENSF409_Final_Project_Database" rel="noopener noreferrer">
                    <ProjectsIcon src={Icon3} alt='picture'/>
                    <ProjectsH2>SaveOnFurniture</ProjectsH2>
                    <ProjectsP>Java, SQL, mySQL, Project Management</ProjectsP>
                </ProjectsCard>
            </ProjectsWrapper>
        </ProjectsContainer>
    )
}

export default Projects
