import React from 'react'

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img, ButtonToLink
} from './InfoElements'

const InfoSection = ({ lightBg, id, imgStart, topLine, lightText, headline, description1, description2, description3, description4, darkText, buttonLabel, img, alt, buttonYes, emailYes }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description1}</Subtitle>
                                <Subtitle darkText={darkText}>{description2}</Subtitle>
                                <Subtitle darkText={darkText}>{description3}</Subtitle>
                                <Subtitle darkText={darkText}>{description4}</Subtitle>
                                {buttonYes &&
                                    <BtnWrap>
                                        <ButtonToLink target="_blank" href="https://drive.google.com/file/d/1G0WYVX78tCxCoxd1ow72Wx5kYgKV8IBk/view?usp=sharing" rel="noopener noreferrer"
                                        >
                                            {buttonLabel}
                                        </ButtonToLink>
                                    </BtnWrap>
                                }
                                {emailYes &&
                                    <BtnWrap>
                                        <ButtonToLink href="mailto:vietan124@gmail.com">
                                            {buttonLabel}
                                        </ButtonToLink>
                                    </BtnWrap>
                                }
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
