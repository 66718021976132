import mySvg1 from '../../images/svg-1.svg'
import mySvg2 from '../../images/svg-2.svg'
import mySvg3 from '../../images/svg-3.svg'

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    headline: 'Skills and Technologies',
    description1: 'Languages: C/C++, Java, Javascript, HTML/CSS, Python, SQL',
    description2: 'Technologies: React.js, Node.js, Rest API, Django, Tailwind CSS',
    description3: 'Databases: MySQL, FaunaDB, MongoDB',
    description4: 'Tools: AWS(S3, EC2, RDS, Lambda), Netlify Functions, Git, JUnit',
    buttonLabel: 'Download Resume',
    imgStart: false,
    img: mySvg1,
    alt: 'Programmer',
    dark: true,
    primary: true,
    darkText: false,
    buttonYes: true,
}

export const homeObjTwo = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'About',
    headline: 'Who is this guy, though?',
    description1: 'I am based in Calgary, currently pursuing a Bachelor of Science in Software Engineering at the University of Calgary.',
    description2: 'I have great passion for web development, creating intuitive, dynamic user experiences. I also take interests in AI and Machine Learning',
    description3: 'Outside of programming, I am a pretty good guitar player and photographer. I love to use these tools to express emotions and as a way to unpack any stress that comes from daily life.',
    imgStart: true,
    img: mySvg2,
    alt: 'Me',
    dark: false,
    primary: false,
    darkText: true,
    buttonYes: false,
}

export const homeObjThree = {
    id: 'contacts',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contacts',
    headline: 'Have a question or want to work together?',
    description1: 'Phone number: (403)-929-4258',
    description2: 'Email: vietan124@gmail.com',
    buttonLabel: 'Send an Email',
    imgStart: true,
    img: mySvg3,
    alt: 'Me',
    dark: false,
    primary: true,
    darkText: true,
    emailYes: true,
}