import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="home"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-10}
                    >
                        Home
                    </SidebarLink>
                    <SidebarLink to="about"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-10}
                    >
                        About
                    </SidebarLink>
                    <SidebarLink to="projects"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-10}
                    >
                        Projects
                    </SidebarLink>
                    <SidebarLink to="contacts"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-10}
                    >
                        Contacts
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute target="_blank" href="https://drive.google.com/file/d/1G0WYVX78tCxCoxd1ow72Wx5kYgKV8IBk/view?usp=sharing" rel="noopener noreferrer">Resume</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar